
import { Component, Vue } from 'vue-property-decorator';
import Label from '@/models/Label';
import WorkflowContainer from '@/components/views/Workflow/WorkflowContainer.vue';
import { CheckUnsavedDataMixin } from '@/mixins/CheckUnsavedData';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { GlobalOptions } from '@/enums/global/GlobalOptions';
import SettingsTopBar from '../components/views/settings/SettingsTopBar.vue';

@Component({
    name: 'WorkflowView',
    components: {WorkflowContainer, SettingsTopBar},
    mixins: [CheckUnsavedDataMixin],
})
export default class WorkflowView extends Vue {
    private hasUnsavedData = false;

    private onChangesInDataMade({state}: { state: boolean }) {
        this.hasUnsavedData = state;
    }

    private async created() {
        EventBus.$on(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
        await Label.getAll();
        Label.insertOrUpdate({
            data: {
                id: GlobalOptions.ALL,
                name: this.$t('Svi') as string,
            },
        });
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.changesInDataMade, this.onChangesInDataMade);
    }
}
